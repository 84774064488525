import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import { HomePageLayoutWrapper } from "../components/HomePageLayoutWrapper";
import { PageWidthWrapper } from "../components/PageWidthWrapper";
import {
  Box,
  Flex,
  ButtonGroup,
  Button,
  Icon,
  SimpleGrid,
  Stack,
  IconButton,
  Text,
} from "@chakra-ui/core";
import { Link } from "gatsby";
import Image from "gatsby-image";
import SEO from "../components/SEO";
import { CaseStudy } from "../types";

const Template: React.FC<PageProps<DataProps>> = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { caseStudy, allCaseStudy } = data; // data.markdownRemark holds your post data
  const [displayedCaseStudies, setDisplayedCaseStudies] = useState<
    AllCaseStudies
  >(allCaseStudy);

  //helper function for the case study slider buttons
  const moveElementInArray = (a: any[], from: number, to: number) => {
    a.splice(to, 0, a.splice(from, 1)[0]);
    return a;
  };
  const slideBackward = () => {
    let allStudies = [...displayedCaseStudies.nodes];
    setDisplayedCaseStudies({
      nodes: moveElementInArray(allStudies, 0, allStudies.length),
    });
  };
  const slideForward = () => {
    let allStudies = [...displayedCaseStudies.nodes];
    setDisplayedCaseStudies({ nodes: moveElementInArray(allStudies, -1, 0) });
  };

  return (
    <HomePageLayoutWrapper showFooter={true} variant="secondary" customSEO>
      <SEO
        title={caseStudy.title}
        description={caseStudy.subTitle}
        image={caseStudy.image.childImageSharp.fixed.src}
      />
      <PageWidthWrapper paddingLeft="0px">
        <Box
          position="relative"
          h={["232px", "390px", "390px"]}
          w={["285px", "585px", "1155px"]}
        >
          <Flex
            mt={[0, "60px"]}
            ml={[0, "60px"]}
            position="absolute"
            bottom="0"
            right="0"
            border="2px"
            borderColor="bluePrimary"
            h={["218px", "330px", "330px"]}
            w={["288px", "588px", "100%"]}
          />
          <Flex
            position="absolute"
            backgroundSize="cover"
            backgroundColor="blackPrimary"
            h={["216px", "330px", "330px"]}
            w={["275px", "575px", "1100px"]}
            align="center"
          >
            <Image
              fluid={caseStudy.image.childImageSharp.fluid}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </Flex>
        </Box>
      </PageWidthWrapper>

      <PageWidthWrapper
        justify="center"
        mt="50px"
        h="100%"
        paddingLeft={["20px", "20px", "257px"]}
        paddingRight={["20px", "20px", "257px"]}
      >
        <Flex direction="column" w="100%" paddingBottom="95px" zIndex={3}>
          <Text color="blackPrimary" fontSize="12px" textTransform="uppercase">
            Case Study
          </Text>
          <Text color="blackPrimary" fontSize="68px">
            {caseStudy?.title}
          </Text>
          <a href={caseStudy.link} target="_blank" rel="noopener noreferrer">
            <ButtonGroup spacing={0} mt="50px" mb="70px">
              <Button
                fontSize={["12px", "14px"]}
                borderRadius="0px"
                color="whitePrimary"
                backgroundColor="bluePrimary"
                h="48px"
                textTransform="uppercase"
              >
                Find Out More
              </Button>
              <Icon
                color="whitePrimary"
                size="48px"
                padding="12px"
                name="chevron-right"
                aria-label="apply"
                backgroundColor="blueTertiary"
              />
            </ButtonGroup>
          </a>

          <Text color="blackPrimary" fontWeight="bold" mb="20px">
            {caseStudy?.subTitle}
          </Text>
          <SimpleGrid columns={[1, 1, 2]} spacing="65px">
            <Text color="blackPrimary">{caseStudy?.para_1}</Text>
            <Text color="blackPrimary">{caseStudy?.para_2}</Text>
          </SimpleGrid>
        </Flex>
      </PageWidthWrapper>
      <Flex
        backgroundColor="blackPrimary"
        w="100%"
        paddingTop="122px"
        paddingBottom="250px"
        position="relative"
      >
        {/* patterned stripes rectangle */}
        <Flex
          w={["127px", "641px"]}
          h={["345px", "800px"]}
          position="absolute"
          right="0"
          top="-400px"
          opacity={0.3}
          background="linear-gradient(135deg, transparent 45.45%, #ababab 45.45%, #ababab 50%, transparent 50%, transparent 95.45%, #ababab 95.45%, #ababab 100%)"
          backgroundSize="31.11px 31.11px"
        />
        <PageWidthWrapper paddingLeft={["60px", "60px", "257px"]}>
          <Stack zIndex={3}>
            <Text fontSize={["16px", "26px"]} color="whitePrimary">
              Stories you may like
            </Text>
            <Flex>
              {displayedCaseStudies.nodes.map((study, i) => {
                return (
                  <Link to={"/case-studies/" + study.slug}>
                    <Box
                      key={i}
                      h={["290px", "530px"]}
                      w={["247px", "450px"]}
                      flexShrink={0}
                      backgroundColor="whitePrimary"
                      cursor="pointer"
                      marginRight={["33px", "92px"]}
                    >
                      <Stack spacing="0px">
                        <Box h={["172px", "330px"]}>
                          <Image
                            fluid={study.image.childImageSharp.fluid}
                            style={{ height: "100%", objectFit: "cover" }}
                          />
                        </Box>

                        <Flex
                          direction="column"
                          h="210px"
                          w="100%"
                          flexShrink={0}
                          paddingY={["15px", "40px"]}
                          paddingX={["15px", "32px"]}
                        >
                          <Text
                            fontSize={["8px", "12px"]}
                            color="bluePrimary"
                            fontWeight="bold"
                            marginBottom="10px"
                            textTransform="uppercase"
                          >
                            Case Study
                          </Text>
                          <Text fontSize={["14px", "26px"]} color="black">
                            {study.title}
                          </Text>
                        </Flex>
                      </Stack>
                    </Box>
                  </Link>
                );
              })}
            </Flex>
            <Flex mt="50px">
              <IconButton
                h="64px"
                w="64px"
                variant="outline"
                color="bluePrimary"
                borderRadius="50%"
                borderColor="bluePrimary"
                icon="chevron-left"
                aria-label="article-back"
                onClick={() => slideBackward()}
                mr="35px"
              />
              <IconButton
                h="64px"
                w="64px"
                icon="chevron-right"
                backgroundColor="bluePrimary"
                borderRadius="50%"
                color="whitePrimary"
                aria-label="article-forward"
                onClick={() => slideForward()}
              />
            </Flex>
          </Stack>
        </PageWidthWrapper>
      </Flex>
    </HomePageLayoutWrapper>
  );
};

export default Template;

interface DataProps {
  caseStudy: {
    title: string;
    link: string;
    subTitle: string;
    para_1: string;
    para_2: string;
    image: {
      childImageSharp: {
        fluid: any;
        fixed: {
          src: string;
        };
      };
    };
  };

  allCaseStudy: AllCaseStudies;
}

interface AllCaseStudies {
  nodes: {
    slug: string;
    title: string;
    image: {
      childImageSharp: {
        fluid: any;
      };
    };
  }[];
}

export const pageQuery = graphql`
  query($slug: String!) {
    caseStudy(slug: { eq: $slug }) {
      title
      link
      subTitle
      para_1
      para_2
      image {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 500) {
            src
          }
        }
      }
    }
    allCaseStudy {
      nodes {
        slug
        title
        image {
          childImageSharp {
            fluid(maxWidth: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
